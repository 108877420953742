body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

a:hover {
  text-decoration: underline;
}

nav {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}